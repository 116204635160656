import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import styles from './LandingPage.module.css';
import { Helmet } from 'react-helmet';
import Card from '@material-ui/core/Card';
import firebase from './helpers/firebase';
import CardActionArea from '@material-ui/core/CardActionArea';
import Mailchimp from './helpers/MailchimpLandingPage';
import TextField from '@material-ui/core/TextField';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';

const darkTheme = createMuiTheme({
  palette: {
    primary: {
      main: green[700],
    },
  },
});

export default class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: {},
      savings: 0,
    };
  }
  async componentDidMount(): void {
    const blogCollection = await firebase.firestore().collection('blog').get();
    let posts = [];
    blogCollection.docs.forEach((doc) => {
      let data = doc.data();
      data['id'] = doc.id;
      posts.push(data);
    });
    const postsSorted = posts.sort((a, b) => b['date'] - a['date']);

    this.setState({ posts: postsSorted });
  }

  onValueChange = (event) => {
    this.setState({ savings: event.target.value / 10 });
  };

  render() {
    return (
      <ThemeProvider theme={darkTheme}>
        <div style={{ overflowX: 'hidden' }}>
          <Helmet>
            <meta
              name="description"
              content="DeWaste is the easiest way to quickly and reliably reduce food waste. Using image analysis, we can give you accurate wastage data instantly."
            />

            <meta
              itemProp="name"
              content="DeWaste | Food Waste Analysis for all dining institutions"
            />
            <meta
              itemProp="description"
              content="DeWaste is the easiest way to quickly and reliably reduce food waste. Using image analysis, we can give you accurate wastage data instantly."
            />
            <meta itemProp="image" content="https://de-waste.com/logo.png" />

            <meta property="og:image" content="https://de-waste.com/logo.png" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="DeWaste" />
            <meta
              property="og:description"
              content="DeWaste is the easiest way to quickly and reliably reduce food waste. Using image analysis, we can give you accurate wastage data instantly."
            />
            <meta property="og:site_name" content="DeWaste" />
            <meta property="og:url" content="https://de-waste.com" />

            <meta name="twitter:card" content="summary" />
            <meta
              name="twitter:image"
              content="https://de-waste.com/logo.png"
            />
            <meta name="twitter:title" content="DeWaste" />
            <meta
              name="twitter:description"
              content="DeWaste is the easiest way to quickly and reliably reduce food waste. Using image analysis, we can give you accurate wastage data instantly."
            />
          </Helmet>

          <Helmet>
            <style>{'body { background-color: white; }'}</style>
          </Helmet>
          <Helmet>
            <meta
              name="description"
              content="DeWaste is the easiest way to quickly and reliably reduce food waste. Using image analysis, we can give you accurate wastage data instantly."
            />
          </Helmet>
          <a href="https://www.de-waste.com/">
            <img
              alt={'DeWaste logo'}
              className={styles.logo}
              src={'img/logo.png'}
            />
          </a>

          <div>
            <img
              src={'img/bgImage.jpg'}
              alt={'DeWaste'}
              className={styles.landingImage}
            />

            <div className={styles.landingImageItems}>
              <h1 className={styles.mainTextH1}>
                <span className={styles.mainText1}>De</span>
                <span className={styles.mainText}>Waste</span>
              </h1>
              <p className={styles.tagline}>
                The new generation of waste management is here.
              </p>
              <br />
              <Button
                className={styles.pilotButton}
                variant="outlined"
                color="inherit"
                href={'#help'}
                title={'Help'}
              >
                Ask about a pilot!
              </Button>
            </div>

            <div className={styles.tractionContainer}>
              <div className={styles.tractionText}>
                <span>Current and Upcoming Customers</span>
              </div>
              <div className={styles.tractionLogosDiv}>
                <a
                  href="https://www.yum.com/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img
                    alt={'YUM Brands'}
                    className={styles.tractionImg}
                    src={'img/yum.png'}
                  />
                </a>
                <a
                  href="https://www.berkeley.edu"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img
                    alt={'UC Berkeley'}
                    className={styles.tractionImg}
                    src={'img/berkeley.png'}
                  />
                </a>
                <a
                  href="https://www.ucr.edu"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img
                    alt={'UC Riverside'}
                    className={styles.tractionImg}
                    src={'img/riverside.png'}
                  />
                </a>
                <a
                  href="https://www.ucdavis.edu"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img
                    alt={'UC Davis'}
                    className={styles.tractionImg}
                    src={'img/davis.png'}
                  />
                </a>
              </div>
            </div>
          </div>

          <Button
            className={styles.dashboardButton}
            variant="outlined"
            color="inherit"
            href={'./dashboard'}
            title={'Dashboard'}
          >
            Sign In
          </Button>
          <Button
            className={styles.blogButton}
            variant="outlined"
            color="inherit"
            href={'./blog'}
            title={'Blog'}
          >
            Blog
          </Button>
          <Button
            className={styles.networkButton}
            variant="outlined"
            color="inherit"
            href={'./network'}
            title={'Network'}
          >
            Network
          </Button>

          <div id="network" className={styles.sectionDivGray}>
            <span className={styles.headerText}>
              The DeWaste Network: Our COVID-19 response
            </span>
            <br />

            <div className={styles.thirtyDiv}>
              <a
                href="https://de-waste.com/network"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img
                  alt={'The DeWaste Network'}
                  className={styles.networkImg}
                  src={'img/network_logo.png'}
                  loading="lazy"
                />
              </a>
            </div>
            <div className={styles.seventyDiv}>
              <span className={styles.networkDescriptionText}>
                Food waste is at an all time high in the COVID-19 lockdown
                because the food supply chain has been broken. Thousands of tons
                of good food goes to waste every day because of lack of buyers.
                <br />
                <br />
                We are launching an online food portal to fill these gaps in the
                supply chain. The DeWaste Network is an online platform where
                farmers, producers, wholesalers, retailers and restaurants can
                connect with others in their proximity and trade in these food
                items so they don't go to waste.
              </span>
            </div>
            <div>
              <Button
                variant="outlined"
                color="inherit"
                target="_blank"
                href="https://de-waste.com/network"
                rel="noreferrer noopener"
              >
                The DeWaste Network Home
              </Button>
            </div>
          </div>

          <div id="why" className={styles.sectionDivGreen}>
            <span className={styles.headerText}>Why Care?</span>
            <div className={styles.reasonContainer}>
              <div className={styles.reasonDiv}>
                <img
                  alt={'Food Costs'}
                  className={styles.reasonImg}
                  src={'img/reason1.png'}
                  loading="lazy"
                />
                <br />
                <span className={styles.reasonMainText}>
                  Savings in
                  <br />
                  Food Costs
                </span>
                <br />
                <span className={styles.reasonStatistic}>~8%</span>
                <br />
                <br />
                <span className={styles.reasonSupplement}>
                  With lower food costs, profit margins will increase
                </span>
              </div>
              <div className={styles.reasonDiv}>
                <img
                  alt={'Fast Returns'}
                  className={styles.reasonImg}
                  src={'img/reason2.png'}
                  loading="lazy"
                />
                <br />
                <span className={styles.reasonMainText}>
                  Measurable
                  <br />
                  Fast Returns
                </span>
                <br />
                <span className={styles.reasonStatistic}>10x</span>
                <br />
                <br />
                <span className={styles.reasonSupplement}>
                  Make a return on your investment very quickly
                </span>
              </div>
              <div className={styles.reasonDiv}>
                <img
                  alt={'Food Waste'}
                  className={styles.reasonImg}
                  src={'img/reason3.png'}
                  loading="lazy"
                />
                <br />
                <span className={styles.reasonMainText}>
                  Reduction in
                  <br />
                  Food Waste
                </span>
                <br />
                <span className={styles.reasonStatistic}>~50%</span>
                <br />
                <br />
                <span className={styles.reasonSupplement}>
                  Also leading to less costs in disposing of wastage
                </span>
              </div>
              <div className={styles.reasonDiv}>
                <img
                  alt={'Carbon Foodprint'}
                  className={styles.reasonImg}
                  src={'img/reason4.png'}
                  loading="lazy"
                />
                <br />
                <span className={styles.reasonMainText}>
                  Reduction in
                  <br />
                  Carbon Foodprint
                </span>
                <br />
                <span className={styles.reasonStatistic}>~4%</span>
                <br />
                <br />
                <span className={styles.reasonSupplement}>
                  A green initiative that every dining institution is moving
                  towards
                </span>
              </div>
              <div className={styles.reasonDiv}>
                <img
                  alt={'Customer Loyalty'}
                  className={styles.reasonImg}
                  src={'img/reason5.png'}
                  loading="lazy"
                />
                <br />
                <span className={styles.reasonMainText}>
                  Increase in
                  <br />
                  Customer Loyalty
                </span>
                <br />
                <span className={styles.reasonStatistic}>45%</span>
                <br />
                <br />
                <span className={styles.reasonSupplement}>
                  Make the food that customers like to eat
                </span>
              </div>
            </div>
          </div>

          <div id="kitchen" className={styles.sectionDivGray}>
            <span className={styles.headerText}>What We Do</span>
            <br />
            <div className={styles.solutionDiv}>
              <div className={styles.thirtyDiv}>
                <img
                  alt={'Leftovers'}
                  className={styles.solutionImg}
                  src={'img/mount2.png'}
                  loading="lazy"
                />
                <br />
              </div>
              <div className={styles.seventyDiv}>
                <span className={styles.solutionsSmallText}>
                  Menu Engineering Using
                </span>
                <br />
                <span className={styles.solutionsMainText}>DeWaste</span>
                <span className={styles.solutionsGreenText}> Excess</span>
                <br />
                <br />
                <span className={styles.solutionsDescriptionText}>
                  The DeWaste Excess is made with cutting edge technology that
                  collects real time data of food leftovers using computer
                  vision. This data is then used to generate daily reports and
                  actionable insights which can be used to reduce food waste by
                  engineering the menu to adapt to customer preferences.
                </span>
                <br />
                <br />
                <div className={styles.solutionsStatisticContainer}>
                  <div className={styles.solutionsStatisticDiv}>
                    <span className={styles.solutionsStatistic}>30%</span>
                    <br />
                    <span className={styles.solutionsStatisticDescription}>
                      reduction in
                      <br />
                      food waste
                    </span>
                  </div>
                  <div className={styles.solutionsStatisticDiv}>
                    <span className={styles.solutionsStatistic}>45%</span>
                    <br />
                    <span className={styles.solutionsStatisticDescription}>
                      increase in
                      <br />
                      customer satisfaction
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.solutionDiv}>
              <div className={styles.thirtyDiv}>
                <img
                  alt={'Kitchen'}
                  className={styles.solutionImg}
                  src={'img/kitchen.png'}
                  loading="lazy"
                />
                <br />
              </div>
              <div className={styles.seventyDiv}>
                <span className={styles.solutionsSmallText}>
                  Optimize backend using
                </span>
                <br />
                <span className={styles.solutionsMainText}>DeWaste</span>
                <span className={styles.solutionsGreenText}> Kitchen</span>
                <br />
                <br />
                <span className={styles.solutionsDescriptionText}>
                  The DeWaste Kitchen helps you seamlessly track kitchen wastage
                  at your dining institutions. It automatically records the
                  weight and composition of the waste and empowers chefs to
                  implement internal control practices to reduce kitchen waste.
                </span>
                <br />
                <br />
                <div className={styles.solutionsStatisticContainer}>
                  <div className={styles.solutionsStatisticDiv}>
                    <span className={styles.solutionsStatistic}>40%</span>
                    <br />
                    <span className={styles.solutionsStatisticDescription}>
                      decrease in
                      <br />
                      kitchen waste
                    </span>
                  </div>
                  <div className={styles.solutionsStatisticDiv}>
                    <span className={styles.solutionsStatistic}>~8%</span>
                    <br />
                    <span className={styles.solutionsStatisticDescription}>
                      decrease in
                      <br />
                      food costs
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <br />
            <br />
            <Button
              variant="outlined"
              color="inherit"
              target="_blank"
              href={'./solution_deck.pdf'}
            >
              Learn more
            </Button>
            <br />
            <br />
          </div>

          <div id="news" className={styles.sectionDivGreenBlog}>
            <div className={styles.blogCardsContainer}>
              <span className={styles.headerText}>The DeWaste Blog</span>
            </div>
            <br />
            <br />
            <br />
            <div className={styles.blogCardsContainer}>
              {Object.keys(this.state.posts)
                .slice(0, 3)
                .map((post) => {
                  let imageUrl =
                    'https://firebasestorage.googleapis.com/v0/b/dewaste.appspot.com/o/blogImages%2F' +
                    this.state.posts[post]['image'] +
                    '?alt=media';
                  let articleUrl = './blog/' + this.state.posts[post]['id'];
                  return (
                    <Card className={styles.newsCard} key={post}>
                      <CardActionArea
                        href={articleUrl}
                        className={styles.cardActionArea}
                      >
                        <img
                          className={styles.cardImage}
                          src={imageUrl}
                          alt={'https://de-waste.com/logo.png'}
                        />
                        <br />
                        <span className={styles.cardMainText}>
                          {this.state.posts[post]['title']}
                        </span>
                        <br />
                        <br />
                        <span className={styles.cardDate}>
                          {this.state.posts[post]['date'].toDate().getMonth() +
                            1 +
                            '/' +
                            this.state.posts[post]['date'].toDate().getDate() +
                            '/' +
                            this.state.posts[post]['date']
                              .toDate()
                              .getFullYear()}
                        </span>
                        <br />
                        <br />
                      </CardActionArea>
                    </Card>
                  );
                })}
              <br />
              <br />
              <br />
              <Button variant="outlined" color="inherit" href={'./blog'}>
                Read More
              </Button>
              <br />
              <br />
            </div>
          </div>

          <div className={styles.sectionDivGreenSubscribe}>
            <div className={styles.signUpBlock}>
              <span className={styles.signUpText}>
                Be the first to learn about the latest DeWaste news.
              </span>
              <hr />
              <br />
              <div className={styles.signUpField}>
                <Mailchimp
                  action="https://de-waste.us18.list-manage.com/subscribe/post?u=0c467677623daa30d3d670b81&amp;id=b21f4d595f"
                  fields={[
                    {
                      name: 'EMAIL',
                      placeholder: 'Email',
                      type: 'email',
                      required: true,
                    },
                  ]}
                />
              </div>
            </div>
          </div>

          <div id="help" className={styles.contactForm}>
            <div className={styles.leftHalfDiv}>
              <div className={styles.calcText}>
                <span className={styles.calcMainText}>Savings Calculator</span>
                <br />
                <br />
                <span className={styles.calcSmallText}>
                  How much do you think you can save?
                </span>
              </div>
              <br />
              <br />
              <TextField
                label="Annual Food Costs"
                variant="outlined"
                onChange={this.onValueChange}
              />
              <br />
              <br />
              <br />
              <br />
              <span className={styles.calcSmallText}>
                Using DeWaste, you can save upto
              </span>
              <br />
              <br />
              <div className={styles.savings}>${this.state.savings}</div>

              <br />
              <br />
              <br />
              <br />
              <br />

              <span className={styles.calcMainText}>Connect With Us</span>
              <br />
              <br />
              <div>
                <a
                  href={'https://twitter.com/dewasteofficial'}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img
                    alt={'Twitter'}
                    className={styles.socialImg}
                    src={'img/twitter.png'}
                    loading="lazy"
                  />
                </a>
                <a
                  href={'https://www.linkedin.com/company/dewaste'}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img
                    alt={'LinkedIn'}
                    className={styles.socialImg}
                    src={'img/linkedin.png'}
                    loading="lazy"
                  />
                </a>
                <a
                  href={'https://www.instagram.com/dewasteofficial/'}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img
                    alt={'Instagram'}
                    className={styles.socialImg}
                    src={'img/instagram.png'}
                    loading="lazy"
                  />
                </a>
                <a
                  href={'https://www.facebook.com/dewasteofficial/'}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img
                    alt={'Facebook'}
                    className={styles.socialImg}
                    src={'img/facebook.png'}
                    loading="lazy"
                  />
                </a>
              </div>
            </div>
            <iframe
              src="https://docs.google.com/forms/d/e/1FAIpQLSdIAD0F9PqmxuWi3aOIrAN3V0SsIO1HABqZ5Byj9fU2Zf--VA/viewform?embedded=true"
              height="896"
              frameBorder="0"
              marginHeight="0"
              marginWidth="0"
              title="landingPageHelp"
              className={styles.rightHalfDiv}
            >
              Loading…
            </iframe>
          </div>
          <div id="Backed By" className={styles.sectionDivGreen}>
            <span className={styles.headerText}>Backed By</span>
            <br />
            <br />
            <br />
            {/* <div className={styles.backedCard}>
              <img
                alt={'YC'}
                className={styles.backedImg}
                src={'img/YCom.png'}
                loading="lazy"
              />
            </div> */}
            <div className={styles.backedCard}>
              <img
                alt={'Skydeck'}
                className={styles.backedImg}
                src={'img/Skydeck.png'}
                loading="lazy"
              />
            </div>
            <div className={styles.backedCard}>
              <img
                alt={'Amp'}
                className={styles.backedImg}
                src={'img/Amp.png'}
                loading="lazy"
              />
            </div>
            <div className={styles.backedCard}>
              <img
                alt={'BIVP'}
                className={styles.backedImg}
                src={'img/BIVP.png'}
                loading="lazy"
              />
            </div>
            {/* <div className={styles.backedCard}>
              <img
                alt={'1517'}
                className={styles.backedImg}
                src={'img/1517.png'}
                loading="lazy"
              />
            </div> */}
            <div className={styles.backedCard}>
              <img
                alt={'Cal Hacks'}
                className={styles.backedImg}
                src={'img/CalHacks.png'}
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </ThemeProvider>
    );
  }
}
