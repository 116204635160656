import React from 'react';
import jsonp from 'jsonp';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

class Mailchimp extends React.Component {
  state = {};

  handleSubmit(evt) {
    evt.preventDefault();
    const { fields, action } = this.props;
    const values = fields
      .map((field) => {
        return `${field.name}=${encodeURIComponent(this.state[field.name])}`;
      })
      .join('&');
    const path = `${action}&${values}`;
    const url = path.replace('/post?', '/post-json?');
    const regex = /^([\w_.\-+])+@([\w-]+\.)+([\w]{2,10})+$/;
    const email = this.state['EMAIL'];
    !regex.test(email)
      ? this.setState({ status: 'empty' })
      : this.sendData(url);
  }

  sendData(url) {
    this.setState({ status: 'sending' });
    jsonp(url, { param: 'c' }, (err, data) => {
      if (data.msg.includes('already subscribed')) {
        this.setState({ status: 'duplicate' });
      } else if (err) {
        this.setState({ status: 'error' });
      } else if (data.result !== 'success') {
        this.setState({ status: 'error' });
      } else {
        this.setState({ status: 'success' });
      }
    });
  }

  render() {
    const { fields, styles } = this.props;
    const messages = {
      ...Mailchimp.defaultProps.messages,
      ...this.props.messages,
    };
    const { status } = this.state;
    return (
      <form
        onSubmit={this.handleSubmit.bind(this)}
        style={{
          width: '100%',
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      >
        <div
          style={{
            display: 'inline-block',
            height: '50px',
            verticalAlign: 'top',
          }}
        >
          {fields.map((input) => (
            <TextField
              label="Email"
              variant="outlined"
              style={{ width: '100%' }}
              {...input}
              key={input.name}
              onChange={({ target }) =>
                this.setState({ [input.name]: target.value })
              }
              defaultValue={this.state[input.name]}
            />
          ))}
        </div>
        <Button
          variant="contained"
          disabled={status === 'sending' || status === 'success'}
          type="submit"
          style={{
            display: 'inline-block',
            height: '53px',
            verticalAlign: 'top',
            marginLeft: '20px',
          }}
        >
          {messages.button}
        </Button>
        <div className="msg-alert">
          {status === 'sending' && (
            <p style={styles.sendingMsg}>{messages.sending}</p>
          )}
          {status === 'success' && (
            <p style={styles.successMsg}>{messages.success}</p>
          )}
          {status === 'duplicate' && (
            <p style={styles.duplicateMsg}>{messages.duplicate}</p>
          )}
          {status === 'empty' && (
            <p style={styles.errorMsg}>{messages.empty}</p>
          )}
          {status === 'error' && (
            <p style={styles.errorMsg}>{messages.error}</p>
          )}
        </div>
      </form>
    );
  }
}

Mailchimp.defaultProps = {
  messages: {
    sending: 'Sending...',
    success: 'Thank you for subscribing!',
    error: 'An unexpected internal error has occurred.',
    empty: 'You must write an e-mail.',
    duplicate: 'Too many subscribe attempts for this email address',
    button: 'Subscribe!',
  },
  buttonClassName: '',
  styles: {
    sendingMsg: {
      color: '#0652DD',
    },
    successMsg: {
      color: '#009432',
    },
    duplicateMsg: {
      color: '#EE5A24',
    },
    errorMsg: {
      color: '#ED4C67',
    },
  },
};

Mailchimp.propTypes = {
  action: PropTypes.string,
  messages: PropTypes.object,
  fields: PropTypes.array,
  styles: PropTypes.object,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
};

export default Mailchimp;
